import React from "react";
import "./Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../assets/logo.png"; //  src/images
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar">
        
      <div className="container">
        <li class="navbar-brand" href="#">
          <NavLink to="/videos">
          <img src={Logo} alt="" width="35" height="30"></img>
          </NavLink>
        </li>
        <h6 className = "title">THE CLASS PLAN</h6>
        <div className="nav-elements">
          <ul>
            <li className="tab">
              <NavLink to="/videos">Videos</NavLink>
            </li>
            <li className="tab"> 
              <NavLink to="/customers">Customers</NavLink>
            </li>
            <li className="tab">
              <NavLink to="/">Logout</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    
  );
}
export default Navbar;
