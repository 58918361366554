
import { BrowserRouter , Route,Routes} from 'react-router-dom'
import Navbar from './components/Navbarstart'
import MyPlayer from './pages/Video-form';
import Customers from './pages/customers';
import LoginSignup from './components/LoginSignup/LoginSignup';
import { useState } from 'react'
import './components/login.css'
import axios from 'axios';
import Video from './pages/videos';
import PrivateRoutes from './components/auth';
import PlayerPrev from './pages/exist-edit';
window.auth = false;
function Login() {
 


  return (
    
    <div className="App">
      <BrowserRouter>
     
      <Routes>
                      <Route
                        exact
                        path="/"
                        element={<LoginSignup/>}
                    />
       {/*     <Route element={<PrivateRoutes />}> */}
                    <Route
                        exact
                        path="/videos"
                        element={<Video/>}
                    />
                      

                      <Route
                        exact
                        path="/editprev"
                        element={<PlayerPrev/>}
                    />
                    
                    <Route
                        exact path="/edit"
                        element={<MyPlayer/>}
                      
                    />
                    <Route
                        exact
                        path="/customers"
                        element={<Customers/>}
                    />
                  
                 {/*   </Route>*/}
                </Routes>
     
      </BrowserRouter>
 


     </div> 
    )
} 


export default Login;
