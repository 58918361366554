import React from "react";
import "./Navbarstart.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../assets/logo.png"; //  src/images
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar">
        
      <div className="container">
        <a class="navbar-brand" href="#">
          <img src={Logo} alt="" width="35" height="30"></img>
        
        </a>
        <h6 className = "title">THE CLASS PLAN</h6>
      </div>
    </nav>

    
  );
}
export default Navbar;
