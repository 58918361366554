import React from "react";
import Navbar from "../components/Navbar";
import "../components/Navbar.css";
const Customers = () => {
    return (
        <>
        <Navbar/>
        <>

            <h1 style={{ color: "green" }}>
                Customers
            </h1>
        </>    
        </>
    );
};
 
export default Customers;