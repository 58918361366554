import React, {useState} from "react";
import axios from 'axios';
import ReactDOM from "react-dom/client";
import "./LoginSignup.css"
import user_icon from "../assets/person.png"
import Navbar from "../Navbarstart";
import "../Navbarstart.css";
import email_icon from "../assets/email.png"
import password_icon from "../assets/password.png"
import { useNavigate } from "react-router-dom";
import Video from "../../pages/videos"
//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
const LoginSignup = () => {

  const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const handleEmail = (event) => {
        setEmail(event.target.value);
      };
      const handlePass= (event) => {
        setPassword(event.target.value);
      };
    const handleSubmit = () => {
      var sha256 = require('js-sha256').sha256;
      var pass = sha256(password);

      const options = {
      method: 'GET',
      url: 'https://springapi-xsvy.onrender.com/usercheck',
      params: {
        email: email,
        password: pass
      }
  };
   
     
     
      axios.request(options).then(function (response) {    
        console.log(options);
          // Does not exist
          console.log(response.headers)
          if(response.headers['access']!=0 || response.headers['exist']!="true"   ){
            alert("Invalid Email/Password");
           
           
          } 
          else {
            window.auth = true;
            navigate("/videos");
           
          }
        
      }).catch(function (error) {
       
          alert("Broken Connection");
          console.error(error);

      });

    }

    return(
      <> <Navbar /> 
        <div className="contlogin">
           
            <div className="header">
            <div className="text">Sign in</div>
            <div className="underline"></div>
            </div>
        <div className="inputs">
        <div className="input">
            <img src = {email_icon} alt=""/>
            <input type="email" value={email} onChange={handleEmail}   placeholder="Email ID"/>
        </div>
        <div className="input">
            <img src = {password_icon} alt=""/>
            <input type="password" value={password} onChange={handlePass}  placeholder="Password"/>
        </div>
        </div>
        <div className="submit-container">
            <div className={"submit"} onClick={handleSubmit}>Login</div>
        </div>
        </div>
        </>
    )
}
export default LoginSignup;